.container {
  width: 100%;
  height: 95vh;
  background-color: var(--gris-fondo);
  padding: 60px 0 4%;
}

.contentContainer {
  width: 80%;
  gap: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 10%;
}

.videosContainer {
  display: flex;
  justify-content: space-around;
  margin: 0 10%;
  height: 230px;
}

.videoBox {
  width: 45%;
  height: 30vh;
}

.actualVideo {
  display: inline-block;
  width: 100%;
  /* ajusta este valor según tus necesidades */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-size: 24px;
  color: var(--rojo);
  margin-top: 5%;
  font-weight: 900;
  text-align: center;
}

.title2 {
  font-size: 24px;
  color: var(--rojo);
  font-weight: 900;
}

.divActual {
  margin: 10px 0 30px 0;
}

.actualUrl {
  color: var(--rojo);
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 10px;
}

.urlVideoActual,
.urlVideoActual:visited,
.urlVideoActual:link {
  color: var(--rojo);
  font-weight: 400;
  text-decoration: none;
}

.divNewVideo {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.newUrl {
  background-color: var(--gris-fondo);
  border: transparent;
  border-bottom: 1px solid black;
  outline: none;
  padding: 4px 0;
  font-size: 20px;
  color: var(--rojo);
}

.newUrl::placeholder {
  color: var(--rojo);
}

.errorMessage {
  color: var(--rojo);
  font-size: 24px;
  text-align: left;
  height: 30px;
  padding-left: 12%;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
}

.containerButton {
  display: flex;
  justify-content: space-between;
}

.button {
  font-size: 10px;
  width: 100px;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  color: #ef4726;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #ef4726;
  padding: 8px;
  margin-bottom: 1rem;
  transition-duration: 0.4s;
}

.button:hover {
  background-color: #ef4726;
  color: white;
  cursor: pointer;
}

.texto {
  color: var(--rojo);
  font-size: 20px;
  font-weight: 600;
  margin-top: 50px;
}

.emailContainer {
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 2%;
  margin: 0 10%;
}

.titleEmail {
  margin-bottom: 15px;
}

.deleteEmail {
  margin-top: 10px;
}

.emailInput {
  width: 38%;
}
