.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15rem;
  background-color: #b8860b;
  padding-top: 2rem;
  position: relative;
}

.goldenBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right,
      #b8860b,
      #f0e68c,
      #f0e68c,
      #b8860b);
  z-index: 100;
}

.container1 {
  background-color: #b8860b;
  bottom: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2rem;
  gap: 10px
}

.test {
  background-color: #f5f5f5;
  bottom: 0;
  width: 60%;
  height: auto;
  display: flex;
  justify-content: space-around;
  padding-top: 4rem;
}

.footer h4 {
  color: white;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 1rem;
}

.footer form h4 {
  margin-bottom: 0;
}

.footer p {
  color: #d6d6d6;
  font-size: 14px;
  line-height: 14px;
}

.footerLink {
  cursor: pointer;
}

.logo {
  width: 250px;
}

.text-muted {
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  line-height: 27px;
  gap: 15px;
}

.container p {
  line-height: 18px;
}

.middleContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  line-height: 30px;
}

.middleContainer h4 {
  margin-bottom: 2rem;
}

.middleContainer p {
  line-height: 2.5rem;
}

/* FORM */
.loginFormFooter {
  z-index: 1;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
}

.flexForm {
  display: flex;
  gap: 0.5rem;
}

.loginFormBody {
  width: 90%;
  padding-bottom: 1rem;
  display: flex;
}

.loginFormInput {
  border-bottom: 1px solid white;
}

.loginFormInput label {
  padding-right: 1rem;
  border: none;
  font-size: 14px;
  font-weight: 400;
  color: white;
}

.loginFormInput input {
  padding-bottom: 5px;
  border: none;
  color: white;
  background-color: transparent;
}

.button {
  margin-left: 1rem;
  margin-bottom: 1rem;
  font-size: 12px;
  /* font-weight: bold; */
  text-transform: uppercase;
  background: transparent;
  color: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: white;
  padding: 5px 12px;
  transition-duration: 0.4s;
}

.button:hover {
  background-color: white;
  color: #b8860b;
  cursor: pointer;
}

.hover p:hover {
  cursor: pointer;
}

.advertencia {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  background-color: #f5f5f5;
}

.footerAdvertencia {
  background-color: #f5f5f5;
}

.errorMessage {
  height: 15px;
  color: #C70017;
}

.newslatter {
  min-width: 500px;
}

@media (max-width: 1200px) {
  .footer {
    padding: 0 10%;
  }

  .middleContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    margin: 15px 0;
  }

  .container {
    width: 100%;
    justify-content: space-between;
  }

  .advertencia {
    height: 100px;
  }

  .container {
    flex-direction: row;
  }

  .container1 {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .newslatter {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100px;
    width: 100%;
  }

  .test {
    width: 100%;
  }

  .advertencia {
    height: auto;
    width: 100%;
    object-fit: contain;
  }

  .logo {
    margin: 2rem auto;
  }
}

@media (max-width: 600px) {
  .newslatter {
    min-width: 50px;
  }

  .middleContainer {
    flex-direction: column;
  }

  .container {
    flex-direction: column;
  }

  .container1 {
    flex-direction: column;
  }

  .test {
    flex-direction: column;
    margin-left: 10%;
    width: auto;
    height: fit-content;
  }

  .newslatter {
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    width: 100%;
  }

  .flexForm {
    flex-direction: column;
    justify-content: flex-start;
    gap: 3px;
    width: 100%;
  }

  .loginFormFooter {
    height: auto;
    width: 100%;
  }

  .loginFormBody {
    width: 100%;
  }

  .loginFormInput {
    width: 100%;
  }

  .logo {
    margin: 2rem auto;
  }
}

.loginFormInput > input:-webkit-autofill,
.loginFormInput > input:-webkit-autofill:hover,
.loginFormInput > input:-webkit-autofill:focus,
.loginFormInput > input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: white !important;
}