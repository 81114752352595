.container {
  width: 100%;
  padding: 50px 0 0;
}

.primeraSeccion {
  height: 100vh;
  background-color: red;
  /* // TODO: falta la primera imagen del producto-1 aca*/
  /* background-image: url(../../../assets/fondo-izquierda-box.jpg) */
}

.firstSection {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(255, 81, 115);
  background: linear-gradient(152deg,
      rgba(255, 81, 115, 1) 0%,
      rgba(130, 18, 41, 1) 100%);
  position: relative;
}

.flexColum {
  height: 100%;
  margin-left: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1rem;
  position: relative;
  z-index: 1;
  padding-top: 40px;
}

.contenedor {
  max-width: 100%;
  overflow: hidden;
}

.containerFaltante {
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  left: 400px;
  top: 0;
  bottom: 0;
}

.pierBox {
  bottom: 0;
  height: 1231px;
  margin: 188px auto auto;
  max-width: 100%;
  overflow: hidden;
  scale: 1.2;
  transform: rotate(-13deg);
  z-index: 2;
  margin-left: 8rem;
  width: 1300px;
}

.marca1 {
  height: 92px;
}

.marca2 {
  height: 176px;
}

.dolchesterSection {
  padding: 5% 0;
  background-color: var(--gris-fondo);
}

.contenedorSecond {
  z-index: 1000;
  width: 100%;
  padding: 3rem 15rem;
}

.secondSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 5rem 0;
  gap: 5rem;
}

.conteinerProductLeft {
  height: 200px;
  background-image: url(../../../assets/fondo-izquierda-box.jpg);
  background-repeat: no-repeat;
  display: flex;
  gap: 100px;
  /* margin: 10px 8%; */
  justify-content: center;
  align-items: center;
}

.conteinerProductRight {
  height: 200px;
  background-image: url(../../../assets/fondo-derecha-box.jpg);
  background-repeat: no-repeat;
  display: flex;
  gap: 100px;
  /* margin: 10px 8%; */
  justify-content: center;
  align-items: center;
}

.niebla {
  background-image: url(../../../assets/fondo-niebla.jpg);
  background-position: center;
  background-size: cover;
  max-width: 100%;
  height: 100vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.legado {
  height: 50px;
}

.invertContainer {
  flex-direction: row-reverse;
  position: relative;
}

.pierBoxes {
  height: 350px;
  z-index: 1;
  transition: transform 1.3s;
}

.pierBoxes:hover {
  transform: scale(1.1);
}

.pierBoxesRed {
  height: 300px;
  z-index: 1;
  margin-left: 3rem;
}

.title {
  color: #b8860b;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 4px;
}

.subtitle {
  font-size: 18px;
  color: var(--negro);
  font-weight: 300;
}

.band {
  width: 4.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.band img {
  display: block;
  width: 2rem;
}

.spaceSubtitle {
  padding-top: 15px;
}

.details {
  font-size: 16px;
  color: var(--gris-texto);
  padding-top: 15px;
}

.argentinianFlag {
  margin-top: 15px;
  height: 20px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  line-height: 20px;
}

.thirdSection {
  background-color: var(--gris-fondo);
  width: 100%;
  padding: 5rem 15rem;
}

/* .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    } */
.fondos {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.goldenSeparator {
  height: 10px;
  width: 100%;
  background-image: linear-gradient(0.25turn,
      var(--dorado-gradient),
      var(--blanco-gradient),
      var(--dorado-gradient));
}

.fifthSection {
  background-image: url(../../../assets/fondo-quinta-seccion.jpg);
  height: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.borde {
  top: 50;
  left: 50;
  height: 230px;
  z-index: 1;
}

.contenedorBorde {
  position: absolute;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 0;
}

.textContainerLeft {
  margin-left: 4rem;
}

.textContainerRight {
  margin-right: 4rem;
}

.conteinerProductFifthSection {
  display: flex;
  gap: 100px;
  margin: 20px 10%;
  justify-content: center;
  align-items: center;
  padding: 3%;
  border: 3px solid transparent;
  border-image-slice: 1;
  width: 80%;
}

.conteinerProductSixthSection {
  display: flex;
  gap: 100px;
  margin: 20px 10%;
  justify-content: center;
  align-items: center;
  padding: 3%;
  border: 3px solid transparent;
  border-image-slice: 1;
  width: 80%;
}

.titlefifthSection {
  color: var(--gris-titulo);
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 5px;
}

.subtitlefifthSection {
  color: #a5a4a4;
  font-size: 16px;
  font-weight: 300;
}

.sixthSection {
  background-color: var(--gris-fondo);
  padding: 3% 0 7%;
}

.sixthSection {
  background: url(../../../assets/background-black.webp);
  height: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: url(../../../assets/background-black.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

@media (max-width: 1100px) {
  .pierBox {
    display: none;
  }
}

@media (max-width: 600px) {
  .contenedorSecond {
    z-index: 1000;
    width: 100%;
    padding: 0;
  }

  .section1 {
    padding: 0;
  }

  .legado {
    height: 35px;
  }

  .niebla {
    flex-direction: column;
  }

  .firstSection {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .faltante {
    display: none;
    left: 0;
  }

  .pierBox {
    height: 650px;
    position: relative;
    max-width: 2025px;
    width: auto;
    margin-left: 2rem;
    margin-top: 37px;
    transform: rotate(-18deg);
  }

  .flexColum {
    height: 100%;
    margin-left: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 1rem;
    position: relative;
    z-index: 1;
    padding-top: 40px;
  }

  .marca {
    height: 150px;
  }

  .containerFaltante {
    max-width: 100%;
    overflow: hidden;
    position: absolute;
    left: 0%;
    top: 27%;
  }

  .textProductBox,
  .textContainerRight,
  .textContainerLeft {
    width: 100%;
  }

  .pierBoxes {
    object-fit: contain;
    width: 250px;
    height: 250px;
  }

  .pierBoxesRed {
    object-fit: contain;
    width: 250px;
    height: 250px;
  }

  .title {
    padding-bottom: 10px;
  }

  .dolchesterSection {
    width: 100%;
    padding: 5% 0;
  }

  .thirdSection {
    padding: 5% 0;
  }
}

@media (max-width: 1200px) {

  .conteinerProductLeft,
  .conteinerProductRight,
  .conteinerProductFifthSection {
    flex-direction: column-reverse;
    justify-content: flex-end;
    background-image: none;
    gap: 20px;
  }

  .conteinerProductSixthSection {
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .textProductBox,
  .textContainerRight,
  .textContainerLeft {
    width: 70%;
  }

  .contenedorBorde {
    display: none;
  }

  .textContainerRight,
  .textContainerLeft {
    margin: 0;
  }

  .titlefifthSection {
    width: 100%;
  }

  .thirdSection {
    padding: 8% 15%;
  }

  .text {
    width: 80%;
    margin: 0 10%;
  }

  .dolchesterSection {
    padding: 5% 17%;
  }
}

@media (max-width: 1200px) {
  .firstSection {
    justify-content: center;
  }

  /* .flexColum {
    margin: 0;
  } */
  /* .contenedor {
    display: none;
  } */
  .secondSection {
    padding-bottom: 15rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0;
    gap: 18rem;
  }
}

@media screen and (min-width: 1600px) {

  /* Estilos para pantallas ultrawide */
  .pierBoxes {
    height: 325px;
    z-index: 1;
    margin-left: 1rem;
  }

  .textProductBox {
    width: 80%;
    /* padding: 0 5rem 0 0; */
  }

  .pierBoxesRed {
    height: 300px;
    z-index: 1;
    margin-right: 9rem;
  }

  .conteinerProductFifthSection {
    gap: 0;
  }

  .textContainerRight {
    margin-right: 16rem;
  }

  .borde {
    display: none;
  }

  .secondSection {
    padding-bottom: 0;
  }
}

.video {
  height: 100vh;
  width: 100%;
  z-index: 100;
  position: relative;
  margin-bottom: -4px;
  /* para sacar el borde blanco inferior */
}

@media screen and (max-width: 1536px) {
  .pierBox {
    height: 923px;
    width: auto;
    bottom: 0;
    z-index: 2;
    overflow: hidden;
    transform: rotate(-12deg);
    margin: auto;
    /* margin-left: 0rem; */
    max-width: 100%;
    margin-top: 156px;
    width: 1026px;
  }
}