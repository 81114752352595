.announcement-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #b8860b;
  color: white;
  padding: 10px 0;
  text-align: center;
  z-index: 1000;
  font-size: 12px;
}

.announcement-text {
  margin: 0;
  padding: 0 20px;
  font-weight: 500;
} 

@media (min-width: 1440px) {
    .announcement-container {
      padding: 10px 40px; 
    }
  }
@media (min-width: 1920px) {
    .announcement-container {
      padding: 10px 200px; 
    }
  }