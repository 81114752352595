/* LOGIN */

.background {
    position: fixed;
    top: 5;
    left: 0;
    width: 100%;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.login {
    position: relative;
    z-index: 1;
    height: 100vh;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login h1 {
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    font-size: 38px;
    margin: 1.5rem;
    font-family: "DIN2014", sans-serif;
    font-weight: 500;
    color: #ffff;
}

.login h3 {
    text-align: center;
    text-transform: uppercase;
    margin: 1rem 0 0 0;
    font-family: "DIN2014", sans-serif;
    font-size: 24px;
    font-weight: bold;
}

.loginForm {
    z-index: 1;
    width: 480px;
    height: 455px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #ffff;
    font-family: "DIN2014", sans-serif;
}

.loginFormBody {
    width: 90%;
    padding-bottom: 1rem;
}

.loginFormInput {
    height: 3rem;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid rgb(8, 8, 8);
}

.loginFormInput label {
    padding-bottom: 5px;
    border: none;
    text-transform: uppercase;
    color: #C70017;
    font-size: 18px;
    font-weight: 400;
}

.loginFormInput input {
    padding-bottom: 5px;
    padding-left: 1rem;
    border: none;
    background-color: #ffffff;
    font-size: 18px;
    font-weight: 400;
}

.conditions {
    z-index: 1;
    padding: 1rem 1px;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 1.0;
    font-weight: 400;
}

.conditions label {
    padding-top: 3px;
    height: 20px;
}

textarea:focus,
input:focus {
    outline: none;
}

label[for="conditions"] {
    padding-left: 7px;
}

.button {
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;
    background: transparent;
    color: #C70017;
    border-style: solid;
    border-width: 1px;
    border-radius: 24px;
    border-color: #C70017;
    padding: 2px 12px;
    transition-duration: 0.4s;
}

.button:disabled {
    background-color: #ffff;
    cursor: not-allowed;
}

.button:hover:not(:disabled) {
    background-color: #C70017;
    color: white;
    cursor: pointer;
}

.resetPasswordSpan{
    padding: 0px 0 8px 0;
    cursor: pointer;
}

.errorMessage{
    color: #C70017;
    min-height: 15px;
}

.resetPassword {
    font-size: 13.5px;
    font-weight: bold;
    color: #2fd0f8;

}

.resetPassword a:visited {
    color: #3bc5e7;
}

.terminos {
    z-index: 1;
    margin-top: 1rem;
    padding: 3px;
    width: 480px;
    height: 150px;
    background-color: rgba(209, 198, 198, 0.452);
    overflow-y: scroll;
}

.terminos p {
    margin: 0;
    padding: 3px;
    text-align: justify;
    font-size: 14px;
    font-weight: 400;
    font-family: "DIN2014", sans-serif;
}

.terminos::-webkit-scrollbar {
    width: 12px;
}

.terminos::-webkit-scrollbar-track {
    padding: 1rem;
}

.terminos::-webkit-scrollbar-thumb {
    background-color: rgba(54, 54, 54, 0.452);
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.linksLegales {
    z-index: 1;
    margin: 1rem 0;
    width: 480px;
    display: flex;
    justify-content: flex-start;
    text-transform: uppercase;
    font-family: "DIN2014", sans-serif;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    gap: 1rem;
    color: #ffff;
}

/* REGLAS GENERALES */

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

html li {
    text-decoration: none;
    list-style-type: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    outline: none;
}

@media (max-width: 480px) {

    /* CSS styles para dispositivos pequeños */
    .background {
        height: 100%;
        width: auto;
    }

    .loginForm {
        width: 310px;
    }

    .terminos {
        width: 310px;
        height: 100px;
    }

    .linksLegales {
        width: 310px;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
}

@media (min-width: 481px) and (max-width: 768px) {

    /* CSS styles para dispositivos medianos */
    .background {
        height: 100%;
        width: auto;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {

    /* CSS styles para dispositivos grandes */
    .background {
        height: 100%;
        width: auto;
    }
}