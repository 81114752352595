.container {
  width: 100%;
  padding: 50px 0 0;
}

.primeraSeccion {
  height: 100vh;
  background-color: red;
}

.firstSection {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('../../../assets/Corona/corona_02.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top 10%;
  position: relative;
}

.flexColum {
  height: 100%;
  display: flex;
  margin: 15em auto 0 41%;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  position: relative;
  z-index: 1;
  padding-top: 40px;
}

.contenedor {
  max-width: 100%;
  overflow: hidden;
}

.marca {
  height: 170px;
}

.dolchesterSection {
  padding: 5% 0;
  background-color: var(--gris-fondo);
}

.contenedorSecond {
  z-index: 1000;
}

.secondSection {
  padding: 20px 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 5rem 0;
  gap: 5rem;
}

.conteinerProductLeft {
  height: 200px;
  background-image: url(../../../assets/fondo-izquierda-box.jpg);
  background-repeat: no-repeat;
  display: flex;
  gap: 100px;
  margin: 10px 8%;
  justify-content: center;
  align-items: center;
}

.conteinerProductRight {
  height: 200px;
  background-image: url(../../../assets/fondo-derecha-box.jpg);
  background-repeat: no-repeat;
  display: flex;
  gap: 100px;
  margin: 10px 8%;
  justify-content: center;
  align-items: center;
}

.niebla {
  background-image: url(../../../assets/Corona-background.png);
  background-position: center;
  background-size: cover;
  max-width: 100%;
  height: 100vh;
  width: 100%;
  padding: 0 15rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerFaltante {
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  left: 300px;
  top: 0;
  bottom: 0;
}

.specialBox {
  bottom: 0;
  height: 80%;
  margin: 8em auto auto;
  max-width: 100%;
  overflow: hidden;
  scale: 1.2;
  transform: rotate(-13deg);
  z-index: 10;
  margin-left: 40rem;
  padding-right: 2rem;
  transition: transform 1.3s;
}

.specialBox:hover {
  transform: scale(1.1);
}

.redFlag {
  top: 0;
  right: 0;
  padding-right: 16rem;
  height: 99%;
  margin: 0 auto auto;
  scale: 1;
  overflow: hidden;
  z-index: 0;
  position: absolute;
}

.legado {
  height: 60px;
}

.invertContainer {
  flex-direction: row-reverse;
  position: relative;
}

.pierBoxes {
  height: 300px;
  z-index: 1;
  margin: 0 4rem 0 0;
  transition: transform 1.3s;
  scale: 1.5;
  transform: rotate(-13deg);
}

.pierBoxes:hover {
  transform: scale(1.1);
}

.pierBoxesRed {
  height: 300px;
  z-index: 1;
  margin-left: 3rem;
}

.title {
  color: #b8860b;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 4px;
}

.subtitle {
  font-size: 18px;
  color: var(--negro);
  font-weight: 300;
}

.spaceSubtitle {
  padding-top: 15px;
}

.details {
  font-size: 16px;
  color: var(--gris-texto);
  padding-top: 15px;
}

.argentinianFlag {
  margin-top: 15px;
  height: 20px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  line-height: 20px;
}

.thirdSection {
  background-color: var(--gris-fondo);
  width: 100%;
  padding: 5rem 15rem;
}

/* .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    } */
.fondos {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.goldenSeparator {
  height: 10px;
  width: 100%;
  background-image: linear-gradient(0.25turn,
      var(--dorado-gradient),
      var(--blanco-gradient),
      var(--dorado-gradient));
}

.fifthSection {
  background-image: url(../../../assets/fondo-quinta-seccion.jpg);
  height: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.borde {
  top: 50;
  left: 50;
  height: 230px;
  z-index: 1;
}

.contenedorBorde {
  position: absolute;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.textContainerLeft {
  width: 45%;
  margin-right: 5rem;
  z-index: 5;
}

.textContainerRight {
  margin-right: 4rem;
}

.conteinerProductFifthSection {
  display: flex;
  gap: 100px;
  margin: 20px 10%;
  justify-content: center;
  align-items: center;
  padding: 3%;
  border: 3px solid transparent;
  border-image-slice: 1;
  width: 80%;
}

.conteinerProductSixthSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;
  border: 3px solid transparent;
  border-image-slice: 1;
}

.titlefifthSection {
  color: var(--gris-titulo);
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 5px;
}

.subtitlefifthSection {
  color: #a5a4a4;
  font-size: 12px;
  font-weight: 300;
  max-width: 100%;
}

.sixthSection {
  background-color: var(--gris-fondo);
  padding: 3% 0 7%;
}

.sixthSection {
  height: 60vh;
  background-color: black;
  background-image: linear-gradient(to right, rgba(139, 0, 0, .7), rgba(139, 0, 0, 1), rgba(139, 0, 0, .7));
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.video {
  height: 100vh;
  width: 100%;
  z-index: 100;
  position: relative;
  margin-bottom: -4px;
  /* para sacar el borde blanco inferior */
}

.band {
  width: 4.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.band img {
  display: block;
  width: 2rem;
}

@media (max-width: 1200px) {

  .conteinerProductLeft,
  .conteinerProductRight,
  .conteinerProductFifthSection {
    flex-direction: column-reverse;
    justify-content: flex-end;
    background-image: none;
    gap: 20px;
  }

  .conteinerProductSixthSection {
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .textProductBox,
  .textContainerRight,
  .textContainerLeft {
    width: 70%;
  }

  .contenedorBorde {
    display: none;
  }

  .textContainerRight,
  .textContainerLeft {
    margin: 0;
  }

  .titlefifthSection {
    width: 100%;
  }

  .thirdSection {
    padding: 3% 15%;
  }

  .text {
    width: 80%;
    margin: 0 10%;
  }

  .dolchesterSection {
    padding: 5% 17%;
  }
}

@media (max-width: 600px) {
  .marca {
    height: 125px;
    /* margin-bottom: 13rem; */
    margin-top: 2rem;
  }

  .legado {
    height: 35px;
  }

  .niebla {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    padding: 2rem;
    gap: 1rem;
  }

  .goldenSeparator {
    height: 13px;
  }

  .firstSection {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .faltante {
    display: none;
  }

  .textProductBox,
  .textContainerRight,
  .textContainerLeft {
    width: 100%;
  }

  .pierBoxes {
    object-fit: contain;
    width: 250px;
    height: 250px;
  }

  .pierBoxesRed {
    object-fit: contain;
    width: 250px;
    height: 250px;
  }

  .title {
    padding-bottom: 10px;
  }

  .dolchesterSection {
    width: 100%;
    padding: 5% 0;
  }

  .thirdSection {
    padding: 8% 15%;
  }

  .contenedor {
    overflow: visible;
  }

  .containerFaltante {
    position: relative;
    left: -2rem;
    top: 8rem;
  }

  .specialBox {
    height: auto;
    width: 130vw;
    max-width: 130vw;
    margin: 0;
  }

  .redFlag {
    display: none;
  }

  .sixthSection {
    height: min-content;
    padding: 2rem;
  }
}

@media (max-width: 1200px) {
  .firstSection {
    justify-content: center;
  }

  .flexColum {
    margin: 0;
  }

  .secondSection {
    padding-bottom: 15rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0;
    gap: 18rem;
  }

  .thirdSection {
    padding: 8% 15%;
  }

  .contenedor {
    overflow: visible;
  }

  .containerFaltante {
    position: relative;
    left: -2rem;
    top: 8rem;
  }

  .specialBox {
    height: auto;
    width: 130vw;
    max-width: 130vw;
    margin: 0;
  }

  .redFlag {
    display: none;
  }

  .sixthSection {
    height: min-content;
    padding: 2rem;
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px) {
  .specialBox {
    display: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .specialBox {
    margin-left: 15rem;
  }
}

@media screen and (min-width: 1600px) {

  /* Estilos para pantallas ultrawide */
  .pierBoxes {
    height: 430px;
    z-index: 1;
    margin: 0 6rem 0 0;
    margin-left: 10rem;
  }

  .textProductBox {
    padding: 0 5rem 0 0;
  }

  .pierBoxesRed {
    height: 430px;
    z-index: 1;
    margin-right: 9rem;
  }

  .conteinerProductFifthSection {
    gap: 0;
  }

  .textContainerRight {
    margin-right: 16rem;
  }

  .borde {
    height: 300px;
  }

  .secondSection {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .borde {
    height: 218px;
  }
}