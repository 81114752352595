.section1 {
  width: 100%;
  padding: 0 15rem;
  height: 100vh;
  background: url(../../../assets/background-pieryroll.jpg);
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 90% center;
  display: flex;
  justify-content: space-around;
  overflow: hidden;
  align-content: stretch;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
}

.rightSide {
  height: 100vh;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.imgSub {
  color: #fff;
  font-family: Apparel;
  font-size: 20px;
  font-weight: lighter;
  margin-top: 15rem;
  margin-bottom: 2rem;
}

.subtitle {
  color: #fff;
  font-family: Apparel;
  font-size: 2em;
  font-weight: lighter;
  margin-top: 15rem;
  margin-bottom: 2rem;
}

.lillosPierArmado {
  height: 607px;
}

.pierRoll {
  width: 70%;
  margin: 0px 0rem 5rem 0rem;
  width: auto;
  z-index: 0;
  transform: rotate(340deg);
  transition: transform 1.3s ease-out;
  /* Animation */
  overflow: hidden;
  opacity: 0;
  animation: fadeIn ease-in 1.5s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.pierRoll:hover {
  transform: scale(1.1);
}

.section2 {
  height: 65vh;
  background: url(../../../assets/background-black.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.contenedorBorde {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.borde {
  /* height: 50vh; */
  max-width: 90vw;
  padding: 3em 0;
}

.contenedorInterno {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.izquierda {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  height: 65vh;
  width: 60%;
  /* line-height: 18px; */
}

.contenedorTexto {
  /* height: 350px;
  width: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contenedorTexto h2 {
  /* font-size: 12px; */
  /* font-weight: 200; */
}

.lillos {
  height: 300px;
  margin: 0;
  padding: 0;
  transition: transform 1.3s;
}

.lillos:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.contenedorSeparador {
  width: 50%;
}

.separador {
  width: 100%;
}

.derecha {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  height: 50%;
  width: 63%;
  line-height: 18px;
  padding-bottom: 1rem;
}

.contenedorTabacos {
  display: flex;
  margin-top: 4rem;
}

.divider {
  width: 10%;
  margin-bottom: 1em;
  margin-left: 2rem;
}

.derecha p {
  margin-bottom: 0;
}

.section2 h2 {
  padding: 0;
  color: #fff;
  font-size: 1.8em;
  line-height: 1.2em;
  font-weight: 400;
  margin: 0rem 0 1em 2rem;
}

.section2 p {
  color: #fff;
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.section2 p:last-child {
  color: #9b9b9b;
  font-size: 16px;
  margin: 0;
  margin-left: 2rem;
  margin-bottom: 1rem;
}

.section3 {
  height: 220px;
  width: 100%;
  background-color: rgb(224, 224, 224);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2rem;
}

.section3Box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section3 h3 {
  /* margin: 2rem 0 2rem 6rem; */
  width: 100%;
  padding-bottom: 2.5rem;
  text-transform: uppercase;
  color: #C70017;
  font-weight: 400;
  font-size: 16px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tresLogos1 {
  height: 60px;
  margin-right: 12.5rem;
}

.tresLogos2 {
  height: 55px;
  margin-right: 12.5rem;
}

.tresLogos3 {
  height: 50px;
  margin-right: 12.5rem;
}

.tresLogos4 {
  height: 50px;
}

@media (max-width: 480px) {
  .lillosPierArmado {
    height: 377px;
  }

  .section1 {
    background: url(../../../assets/home-mobile.png);
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  /* CSS styles for small devices */
  .rightSide {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 12rem;
    margin-left: 1rem;
  }

  .section1 {
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    padding: 0;
  }

  .imgSub {
    color: #fff;
    font-size: 23px;
    font-family: "Apparel";
    font-weight: lighter;
    margin-right: 0;
    position: absolute;
    bottom: 0;
    margin-bottom: 2rem;
    text-align: left;
  }

  .imgPier1 {
    height: 213px;
    margin: 0;
  }

  .section2 {
    height: auto;
  }

  .contenedorTexto {
    width: 372px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contenedorBorde {
    display: none;
  }

  .separador {
    display: none;
  }

  .contenedorInterno {
    position: inherit;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lillos {
    height: 250px;
    margin: 0;
    padding: 0;
  }

  .izquierda {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .derecha {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .section2 h2 {
    margin: 2rem 0 0.5rem 2rem;
    font-size: 16px;
  }

  .section2 p {
    font-size: 16px;
  }

  .pierRoll {
    width: 90%;
    transform: rotate(340deg);
  }

  .section3 {
    height: auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section3 h3 {
    margin: 0 0 2rem 0;
    text-align: center;
  }

  .tresLogos {
    height: 50px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .pierRoll {
    margin: 0;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .lillosPierArmado {
    height: 377px;
  }

  /* CSS styles for medium devices */
  .rightSide {
    height: 100vh;
    display: flex;
    justify-content: space-around;
  }

  .section1 {
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
    margin: 0;
    padding: 0;
  }

  .imgSub {
    color: #fff;
    font-size: 12px;
    font-family: "Apparel";
    font-weight: lighter;
    margin-top: 0rem;
    margin-right: 0;
    width: 100%;
    text-align: center;
  }

  .imgPier1 {
    height: 350px;
    margin-bottom: 4rem;
    margin-top: 0;
  }

  .pierRoll {
    margin-top: 16rem;
  }

  .section2 {
    height: auto;
  }

  .contenedorTexto {
    height: 350px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .contenedorBorde {
    display: none;
  }

  .separador {
    display: none;
  }

  .contenedorInterno {
    position: inherit;
    padding-top: 2rem;
  }

  .lillos {
    height: 320px;
    margin: 0;
    padding: 0;
  }

  .izquierda {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .derecha {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .section3 {
    height: auto;
    padding: 1rem;
  }

  .tresLogos {
    height: 50px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .lillosPierArmado {
    height: 377px;
  }

  /* CSS styles for large devices */
  /* CSS styles for extra large devices */
  .derecha {
    height: 60%;
    width: 100%;
    line-height: 18px;
  }

  .izquierda {
    height: 50%;
    width: 100%;
    line-height: 18px;
  }

  .borde {
    display: none;
  }

  .section3 {
    height: auto;
    padding: 1rem;
  }

  .tresLogos {
    height: 60px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1440px) {

  /* CSS styles for extra large devices */
  .derecha {
    height: 60%;
    width: 70%;
    line-height: 18px;
  }

  .izquierda {
    height: 50%;
    width: 70%;
    line-height: 18px;
  }

  /* .section2 {
        height: 130vh;
    } */
  .borde {
    display: none;
  }
}

@media (max-width: 1200px) {

  /* CSS styles for extra large devices */
  .derecha {
    height: 60%;
    width: 80%;
    line-height: 18px;
  }

  .izquierda {
    height: 50%;
    width: 80%;
    line-height: 18px;
  }

  .borde {
    display: none;
  }
}

@media (max-width: 1066px) {

  /* CSS styles for extra large devices */
  .derecha {
    height: 60%;
    width: 90%;
    line-height: 18px;
  }

  .izquierda {
    height: 50%;
    width: 90%;
    line-height: 18px;
  }
}

@media (min-width: 1540px) {
  .lillos {
    height: 488px;
    margin: 0;
    padding: 0;
    transition: transform 1.3s;
  }
}

.video {
  height: 100vh;
  width: 100%;
  z-index: 100;
  position: relative;
  margin-bottom: -4px;
  /* para sacar el borde blanco inferior */
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .imgSub {
    margin-top: 22rem;
  }

  .pierRoll {
    margin: 0px 0rem 1rem 0rem;
  }

  .lillosPierArmado {
    height: 477px;
  }

  .borde {
    /* height: 107vh; */
    width: auto;
    padding: 3em 0;
  }

  .section2 p {
    font-size: 14px;
  }

  .section2 h2 {
    margin: -1rem 0 1rem 2rem;
  }
}