@import url(https://db.onlinewebfonts.com/c/e42e255304389bb383e86ad558fc1968?family=Eurostile+LT+W01+Bold);

:root {
  --rojo: #C00215;
  --dorado-gradient: #A37736;
  --blanco-gradient: #F9EED4;
  --gris-titulo: #e3e4e4;
  --gris-texto: #7a7a7a;
  --gris-fondo: #DFDFDF;
  --negro-texto: #646464;
  --size-texto: 14px;
  --fuente-bold-italic: url('./fonts/DIN2014-BoldItalic.eot');
  --fuente-extra-bold-italic: url('./fonts/DIN2014-ExtraBoldItalic.eot');
  --fuente-italic: url('./fonts/DIN2014-Italic.eot');
  --fuente-italic-light: url('./fonts/DIN2014-LightItalic.eot');
  --fuente-italic-extra-light: url('./fonts/DIN2014-ExtraLightItalic.eot');
  --fuente-regular: url('./fonts/DIN2014-Regular.eot');
  --fuente-light: url('./fonts/DIN2014-Light.eot');
  --fuente-extra-light: url('./fonts/DIN2014-ExtraLight.eot');
  --fuente-semi-bold: url('./fonts/DIN2014-DemiBold.eot');
  --fuente-bold: url('./fonts/DIN2014-Bold.eot');
  --fuente-extra-bold: url('./fonts/DIN2014-ExtraBold.eot');
  /* --Apparel-bold: url('./fonts/ApparelBold.ttf');
  --Apparel-regular: url('./fonts/Apparel-Regular.ttf');
  --Apparel-light: url('./fonts/ApparelLight.ttf');

  --Apparel-display-black: url('./fonts/ApparelDisplayBlack.ttf');
  --Apparel-display-light: url('./fonts/ApparelDisplayLight.ttf');
  --Apparel-display-regular: url('./fonts/ApparelDisplayRegular.ttf'); */
  --Apparel-thin: url('./fonts/ApparelDisplayThin.ttf');
}

/* @font-face {
  font-family: 'Apparel';
  src: var(--Apparel-light);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Apparel';
  src: var(--Apparel-regular);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Apparel';
  src: var(--Apparel-bold);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Apparel';
  src: var(--Apparel-display-black);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Apparel';
  src: var(--Apparel-display-light);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Apparel';
  src: var(--Apparel-display-regular);
  font-weight: 400;
  font-style: normal;
} */
@font-face {
  font-family: 'PPSANZ';
  src: url('./fonts/PPRightSans-WideMedium.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Apparel';
  src: url('./fonts/ApparelDisplayLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Apparel';
  src: url('./fonts/ApparelDisplayRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Apparel';
  src: url('./fonts/ApparelDisplayBlack.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: url('./fonts/DIN2014-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: url('./fonts/DIN2014-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: url('./fonts/DIN2014-DemiBold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-semi-bold);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-regular);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-light);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-extra-light);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-semi-bold);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-bold);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-extra-bold);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-bold-italic);
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-italic);
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-italic-light);
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'DIN2014';
  src: var(--fuente-italic-extra-light);
  font-weight: 100;
  font-style: italic;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "DIN2014", sans-serif;
}