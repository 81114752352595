/* LOGIN */

.background {
    position: fixed;
    top: 5;
    left: 0;
    width: 100%;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.login {
    position: relative;
    z-index: 1;
    height: 100vh;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logoLogin{
    height: 72px;
    margin-top: 2rem;
}


.login h1 {
    z-index: 1;
    text-align: center;
    text-transform: uppercase;
    font-size: 38px;
    margin: 1rem;
    font-family: "DIN2014", sans-serif;
    font-weight: 400;
    color: #ffff;
}

.login h3 {
    text-align: center;
    text-transform: uppercase;
    margin: 3rem 0 0 0;
    font-family: "DIN2014", sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: #b8860b;
    letter-spacing: 1px;
}



.gracias {
    text-align: center;
    text-transform: uppercase;
    font-family: "DIN2014", sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: var(--rojo);
    letter-spacing: 1px;
    margin-bottom: 1.1rem;
    margin-top: 5rem;;
}

.esMayor {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
}

.esMayor h4 {
    font-size: 20px;
    font-family: "DIN2014", sans-serif;
    font-weight: 500;
    color: #787878; 
}

.esMayor h4:hover {
    cursor: pointer;
}

.conditions {
    width: 90%;
    z-index: 1;
    padding: 3rem 1px 0px 1px;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    line-height: 1.0;
    font-weight: 400;
    font-family: "DIN2014", sans-serif;
    color: #090909;

}


.loginForm {
    margin-top: 5rem;
    z-index: 1;
    width: 420px;
    height: 364px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
    align-items: center;
    background-color: #ffff;
    font-family: "DIN2014", sans-serif;
}

.goldenBar {
    z-index: 1;
    width: 420px;
    height: 20px;
    background: linear-gradient(
        to right,
        #b8860b, 
        #f0e68c, 
        #f0e68c, 
        #b8860b  
    );
}

.loginFormBody {
    width: 90%;
}

.loginFormInput {
    height: 3rem;
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid rgb(8, 8, 8);
}

.loginFormInput label {
    padding-bottom: 5px;
    border: none;
    text-transform: uppercase;
    color: #C70017;
    font-size: 15px;
    font-weight: 400;
}

.loginFormInput input {
    padding-bottom: 5px;
    padding-left: 1rem;
    border: none;
    background-color: #ffffff;
    font-size: 15px;
    font-weight: 400;
}

.conditions label {
    padding-top: 3px;
    height: 20px;
}

textarea:focus,
input:focus {
    outline: none;
}

.button {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    background: transparent;
    color: #C70017;
    border-style: solid;
    border-width: 1px;
    border-radius: 24px;
    border-color: #C70017;
    padding: 4px 12px;
    transition-duration: 0.4s;
    margin-bottom: .5rem;
}

.button:disabled {
    background-color: #ffff;
    cursor: not-allowed;
}

.button:hover:not(:disabled) {
    background-color: #C70017;
    color: white;
    cursor: pointer;
}

.resetPasswordSpan{
    padding: 0px 0 8px 0;
    cursor: pointer;
}

.errorMessage{
    color: #C70017;
    min-height: 15px;
}

.resetPassword {
    font-size: 13.5px;
    font-weight: bold;
    color: #2fd0f8;

}

.resetPassword a:visited {
    color: #3bc5e7;
}

.terminos {
    z-index: 1;
    margin-top: 1rem;
    padding: 3px;
    width: 420px;
    height: 150px;
    background-color: rgba(231, 231, 231, 0.836);
    overflow-y: scroll;
}

.terminos p {
    margin: 0;
    padding: 3px;
    text-align: justify;
    font-size: 12px;
    font-weight: 400;
    font-family: "DIN2014", sans-serif;
}

.terminos::-webkit-scrollbar {
    width: 12px;
}

.terminos::-webkit-scrollbar-track {
    padding: 1rem;
}

.terminos::-webkit-scrollbar-thumb {
    background-color: rgba(54, 54, 54, 0.452);
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

.linksLegales {
    z-index: 1;
    margin: 1rem 0;
    width: 420px;
    display: flex;
    justify-content: flex-start;
    text-transform: uppercase;
    font-family: "DIN2014", sans-serif;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    gap: 1rem;
    color: #ffff;

}

/* REGLAS GENERALES */

a:link {
    text-decoration: none;
}

a:visited {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

a:active {
    text-decoration: none;
}

html li {
    text-decoration: none;
    list-style-type: none;
}

.loginFormInput > input:-webkit-autofill,
.loginFormInput > input:-webkit-autofill:hover,
.loginFormInput > input:-webkit-autofill:focus,
.loginFormInput > input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #ffff inset !important;
  -webkit-text-fill-color: var(--rojo) !important;
}





@media (max-width: 480px) {


    .conditions {
        text-align: start;
    }

    .login h1 {
        z-index: 1;
        text-align: center;
        text-transform: uppercase;
        font-size: 38px;
        margin: 1rem;
        font-family: "DIN2014", sans-serif;
        font-weight: 400;
        color: #ffff;
    }

    .logoLogin{
        height: 55px;
        margin-top: 2rem;
    }

    .login h3 {
        text-align: center;
        text-transform: uppercase;
        margin: 1.5rem 0 0 0;
        font-family: "DIN2014", sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: var(--rojo);
        letter-spacing: 1px;
    }

    .esMayor {
        display: flex;
        gap: 2rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    
    .esMayor h4 {
        font-size: 15px;
        font-family: "DIN2014", sans-serif;
        font-weight: 500;
        color: #787878; 
    }

    .loginForm {
        height: 360px;
    }

    .conditions {
        padding-top: 9px;
    }

    /* CSS styles para dispositivos pequeños */
    .background {
        height: 100%;
        width: auto;
    }

    .loginForm {
        width: 310px;
    }

    .terminos {
        width: 310px;
        height: 100px;
    }

    .linksLegales {
        width: 310px;
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }
}

@media (min-width: 481px) and (max-width: 768px) {


    .conditions {
        text-align: start;
    }

    .logoLogin{
        height: 55px;
        margin-top: 2rem;
    }

    .login h3 {
        text-align: center;
        text-transform: uppercase;
        margin: 1.5rem 0 0 0;
        font-family: "DIN2014", sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: var(--rojo);
        letter-spacing: 1px;
    }

    .esMayor {
        display: flex;
        gap: 2rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    
    .esMayor h4 {
        font-size: 15px;
        font-family: "DIN2014", sans-serif;
        font-weight: 500;
        color: #787878; 
    }

    /* CSS styles para dispositivos medianos */
    .background {
        height: 100%;
        width: auto;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {

    /* CSS styles para dispositivos grandes */
    .background {
        height: 100%;
        width: auto;
    }
}