.goldenBar {
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right,
      #b8860b,
      #f0e68c,
      #f0e68c,
      #b8860b);
  z-index: 100;
}
.goldenBar2 {
  position: fixed;
  top: 140px;
  left: 0;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right,
      #b8860b,
      #f0e68c,
      #f0e68c,
      #b8860b);
  z-index: 100;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  z-index: 99999;
}

.Navbar {
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font-family: var(--fuente-principal);
  width: 100vw;
  padding: 1rem 15rem;
  height: 100px;
}

.leftIcon {
  padding-left: 4px;
  width: 5px;
  height: 2px;
  background-color: blue;
}

.logo {
  height: 66px;
}

li {
  font-family: "DIN2014", sans-serif;
}

a:hover {
  cursor: pointer;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 25px;
}

.nav a {
  text-transform: uppercase;
  text-decoration: none;
  color: #b8860b;
  font-size: 14px;
}

.active {
  font-size: 14px;
  color: #000000ad !important;
}

.contact {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  color: rgb(176, 3, 3);
}

.linkedin {
  color: blue;
}

.contact h4 {
  height: 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  color: rgb(255, 0, 43);
}

.products {
  /* position: relative; */
  margin-top: 2px;
  cursor: pointer;
  width: 90px;
  background-color: #fff;
  border-radius: 4px;
}

.dropdownOpen {
  position: absolute;
  top: 100;
  left: 0;
  display: block;
  max-height: 100px;
  transition: max-height 0.3s ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 75px;
}

.dropdownClose {
  position: absolute;
  top: 100;
  left: 0;
  display: none;
  max-height: 0;
  transition: max-height 0.3s ease-out;
}

.dropdown {
  position: absolute;
  top: 100px;
  left: 0;
  display: block;
  max-height: 0;
  overflow: hidden;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 15rem;
  background-color: #fff;
  transition: max-height 0.3s ease-out;
  gap: 2em;
}

.dropdown.open {
  max-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15rem;
  width: 100%;
  background-color: #fff;
  transition: max-height 0.3s ease-in;
  gap: 2em;
}

.tresLogos {
  height: 80px;
  filter: opacity(0.5);
  filter: grayscale(100%);
}
.tresLogos:hover {
  filter: grayscale(0%);
}

.coronaLogo {
  height: 25px;
  margin: 15px 0 30px 0;
}

.activeLogo {
  border-bottom: none;
}

.headerProducts {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  color: #b8860b;
}

.leftIcon {
  margin-left: 1px;
  background-color: black;
  transform: rotate(45deg);
  border-radius: 40px;
  position: absolute;
  left: 78px;
  width: 8px;
  height: 1px;
  transition: 0.5s;
}

.rightIcon {
  width: 8px;
  height: 1px;
  background-color: black;
  transform: rotate(-45deg);
  border-radius: 40px;
  position: absolute;
  left: 84px;
  transition: 0.5s;
}

/* .spanProducto {
  margin-top: 4px;
} */
.leftIconOpen {
  transform: rotate(135deg);
}

.rightIconOpen {
  transform: rotate(-135deg);
}

.arrowActive {
  background-color: #cac4c4;
}

/* MenuHamburguesa.module.css */
.hamburguesa {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.linea {
  width: 100%;
  height: 3px;
  background-color: #b8860b;
  margin-bottom: 4px;
  transition: transform 0.3s, opacity 0.3s;
}

.linea1 {
  transform: rotate(45deg) translate(6px, 6px);
}

.linea2 {
  opacity: 0;
}

.linea3 {
  transform: rotate(-45deg) translate(4px, -4px);
}

.menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  margin-bottom: 10px;
}

.menu li a {
  color: #000;
  text-decoration: none;
}

.dropdownMenu {
  background-color: #fff;
  border-radius: 4px;
}

.dropdownMenu:hover {
  cursor: pointer;
}

.li {
  background-color: #fff;
}

.dropdownMenu li:last-child {
  border-radius: 0 0 5px 5px;
}

.activeContacto {
  color: rgb(183, 0, 0);
}

.activeContacto h4 {
  font-weight: 400;
  color: rgb(183, 0, 0);
}

.NavIzq {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.menuAbierto {
  background-color: #fff;
  /* width: 100vw; */
  display: flex;
  flex-direction: row;
  transition: max-height 0.3s ease-in;
  max-height: 500px;
  /* Ajusta la altura máxima deseada */
}

@media (max-width: 1200px) {
  .Navbar {
    width: 100vw;
    padding: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    transition: max-height 0.3s ease-out;
  }

  .nav {
    position: absolute;
    top: 66px;
    left: 0;
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    gap: 7rem;
    width: 100vw;
    background-color: #fff;
    padding: 0;
    transition: max-height 0.8s ease-out;
    transition: padding 0.8s ease-out;
    max-height: 0;
    height: 100vh;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .nav a {
    font-size: 20px;
  }

  .headerProducts {
    font-size: 20px;
  }

  .leftIcon {
    margin-left: 38px;
  }

  .rightIcon {
    margin-left: 27px;
  }

  .nav li:last-child {
    padding-bottom: 1rem;
  }

  .li {
    width: auto;
  }

  .dropdown {
    /* position: absolute; */
    top: 50px;
    left: 160px;
    flex-direction: column;
    /* bottom: 0; */
    /* left: 0; */
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    padding-left: 22px;
    padding-top: 20px;
  }

  .dropdown.open {
    max-height: 80%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    padding: 0;
  }

  .menuAbierto {
    max-height: 1200px;
    margin-top: 15px;
    background-color: #fff;
    transition: max-height 0.8s ease-in;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    padding: 30px;
  }

  .contact {
    display: none;
  }

  .hamburguesa {
    display: flex;
  }

  .logo {
    padding-right: 1rem;
  }
}

@media (max-width: 850px) {
  .section3 {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
  }

  .flex {
    flex-direction: column;
    gap: 20px;
  }

  .section3 {
    height: auto;
    gap: 20px;
  }

  .section3 h3 {
    width: max-content;
    text-align: center;
  }

  .tresLogos1 {
    height: 60px;
  }

  .tresLogos2 {
    height: 60px;
  }

  .tresLogos3 {
    height: 60px;
  }

  .tresLogos4 {
    height: 60px;
  }

  .logo {
    padding-right: 0rem;
  }
}