.container {
  width: 100%;
  padding: 0 15rem;
  /* padding: 70px 0; */
  background-color: var(--gris-fondo);
}

.firstSection {
  height: 60vh;
  background-image: url(../../assets/bronway-header.jpg);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
}

.secondSection {
  padding-bottom: 4%;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  width: auto;
  margin: 0 10%;
  text-align: justify;
}

.title {
  padding: 4% 0 10px;
  color: #b8860b;
  font-size: 14px;
  font-weight: 400;
  margin: 0 10%;
}

.subtitle {
  color: var(--negro-texto);
  font-weight: 300;
}

.rrhhText {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  color: var(--negro-texto);
  font-weight: 300;
}

.rrhhText a {
  margin-top: 2px;
  color: #b8860b;
}

.video {
  width: 560px;
  height: 315px;
}

.spaceSubtitle {
  padding-top: 15px;
}

.firstBox {
  width: 50%;
}

.secondBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.brandsBox {
  display: flex;
  justify-content: space-between;
}

.thirdSection {
  height: 500px;
}

.fourthSection {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  padding: 30px 15rem 0;
  background-color: var(--gris-fondo);
}

.firstBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 47%;
}

.secondBoxForms {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.firstPartForm {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 7%;
}

.boxImputs {
  width: 50%;
}

.lastPartForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.messageImput {
  width: 100%;
  color: #b8860b;
  border: transparent;
  border-bottom: 1px solid black;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  height: 70px;
}

.sendForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #b8860b !important;
}

.inputs {
  color: #b8860b;
  border: transparent;
  border-bottom: 1px solid black;
  outline: none;
  width: 100%;
  font-size: 14px;
  background-color: transparent;
  padding: 6px 0;
  margin: 5% 0 0 0;
  font-weight: 500;
}

.inputs::placeholder,
.messageImput::placeholder {
  color: #b8860b;
  padding-bottom: 10px;
}

.textForm {
  color: var(--gris-texto);
}

.buttonForm {
  font-size: 10px;
  width: 15%;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  color: #b8860b;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #b8860b;
  padding: 8px;
  margin-bottom: 1rem;
  transition-duration: 0.4s;
}

.buttonForm:hover {
  background-color: #b8860b;
  color: white;
  cursor: pointer;
}

.titleForm {
  color: #b8860b;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 10px;
}

.error {
  display: block;
  color: red;
  height: 15px;
  margin-bottom: 12px;
  font-size: 12px;
}

.containerInput {
  height: 60px;
}

@media (max-width: 1200px) {
  .rrhhText {
    margin-top: 20px;
  }

  .secondSection {
    flex-direction: column;
    margin: 0 20%;
  }

  .title,
  .titleForm {
    text-align: center;
    width: 100%;
  }

  .firstBox {
    width: 100%;
  }

  .secondBox {
    align-items: center;
    margin: 0 auto;
  }

  .fourthSection {
    flex-direction: column;
    padding: 30px 10%;
  }

  .secondBoxForms {
    width: 100%;
  }

  .video {
    width: 476px;
    height: 268px;
  }

  .section3 {
    height: auto;
    padding: 1rem;
  }

  .section3 h3 {
    margin: 0;
    text-align: center;
  }

  .tresLogos {
    height: 50px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 1rem;
  }

  .title {
    margin: 0 0 2rem 0;
  }
}

@media (max-width: 600px) {
  .title {
    margin: 0 0 2rem 0;
  }

  .video {
    width: 344.25px;
    height: 193.8px;
  }

  .secondSection {
    margin: 0 12%;
  }

  .firstPartForm {
    flex-direction: column;
    justify-content: flex-start;
  }

  .boxImputs {
    width: 100%;
  }

  .sendForm {
    flex-direction: column-reverse;
    align-items: center;
  }

  .textForm {
    text-align: left;
  }

  .buttonForm {
    width: 100%;
  }

  .section3 {
    height: auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section3 h3 {
    margin: 0 0 2rem 0;
    text-align: center;
  }

  .tresLogos {
    height: 50px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}

/* estilos para los 4 logos */
.section3 {
  background-color: rgb(224, 224, 224);
  display: flex;
  align-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.section3 h3 {
  margin: 0 0 2rem 0;
  padding-top: 2rem;
  text-transform: uppercase;
  color: #c70017;
  font-weight: 400;
  font-size: 16px;
}

.flex {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.tresLogos {
  height: 30px;
  width: 100%;
}