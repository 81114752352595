.section3 {
  height: 210px;
  width: 100%;
  background-color: rgb(224, 224, 224);
  display: flex;
  align-content: stretch;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section3Box {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2em;
}

.section3 h3 {
  width: 100%;
  padding-bottom: 2.5rem;
  text-transform: uppercase;
  color: #C70017;
  font-weight: 400;
  font-size: 18px;
  margin-left: 2rem;
  padding-top: 2rem;
}

.flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em 2em;
  flex-wrap: wrap;
}

.tresLogos1, .tresLogos2, .tresLogos3, .tresLogos4, .tresLogos5 {
  height: 120px;
}

.tresLogos5 {
  mix-blend-mode: multiply;
}

.coronaLogo {
  height: 30px;
  margin: 5px 0 30px 0;
}

@media (max-width: 850px) {
  .section3 {
    display: flex;
    flex-direction: column;
    /* padding: 2rem 0; */
  }

  .flex {
    flex-direction: column;
    padding: 1em 0;
    /* gap: 20px; */
  }

  .section3 {
    height: auto;
    /* gap: 20px; */
  }

  .section3 h3 {
    width: max-content;
    text-align: center;
  }

  .tresLogos1, .tresLogos2, .tresLogos3, .tresLogos5 {
    height: 80px;
  }

  .coronaLogo {
    height: 25px;
    margin-top: 1rem;
  }
}

@keyframes slide-in {
  0% {
    left: -100%;
    /* Comienza la animación con la imagen fuera del contenedor */
  }

  100% {
    left: 0;
    /* Termina la animación con la imagen completamente visible dentro del contenedor */
  }
}

@media screen and (min-width: 851px) and (max-width: 1200px) {
  .tresLogos1, .tresLogos2, .tresLogos3, .tresLogos5 {
    height: 80px;
  }

  .coronaLogo {
    height: 25px;
    margin-top: 1rem;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .tresLogos1, .tresLogos2, .tresLogos3, .tresLogos5 {
    height: 80px;
  }
}