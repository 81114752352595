.modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    width: 100wh;
    height: 12px;
    padding: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 10001;
    font-family: "DIN2014", sans-serif;
}

.loginFormModal {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 12px;
}

.loginFormModal input{
    border: none;
    border-bottom: solid 1px #ef4726;
    background-origin: padding-box;
}

.modal p {
    padding: 0;
    margin: 0;
    font-size: 10px;
    color: #868686;
    width: 70%;
    max-height: 100%;
    overflow: hidden;
}

.modal button  {
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    background: transparent;
    color: #ef4726;
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;
    border-color: #ef4726;
    padding: 6px;
    margin: 1rem;
    cursor: pointer;
}

.modalContent {
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: space-between;
}

.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 9rem;
}

.footer p {
    font-size: 10px;
    color: #868686;
    width: 50%;
}

.footer a {
    text-decoration: none;
}

.footer h4 {
    font-size: 10px;
    text-transform: uppercase;
    color: #ef4726;
}

.footerButton  {
    font-size: 10px;
    text-transform: uppercase;
    background: transparent;
    color: #ef4726;
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;
    border-color: solid 1 px #ef4726;
    padding: 8px;
}

.footerButton:hover {
    background-color: #ef4726;
    /* Green */
    color: white;
    cursor: pointer;
}

.loginForm {
    z-index: 1;
    width: 350px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffff;
}

.loginFormBody {
    width: 90%;
    padding-bottom: 1rem;
}

.loginFormInputEmail {
    display: flex;
    border-bottom: 1px solid rgb(8, 8, 8);
}

.loginFormInput label {
    padding-right: 1rem;
    border: none;
    text-transform: uppercase;
    color: #ef4726;
    font-size: 10px;
    font-weight: 700;
}

.loginFormInput input {
    padding-bottom: 5px;
    border: none;
    background-color: #ffffff;
}