.container {
  width: 100%;
  padding: 50px 0 0;
}

.primeraSeccion {
  height: 100vh;
  background-color: red;
}

.firstSection {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url(../../../assets/Liverpool/FondoLiverpool.png);
  background-position: center;
  /* background-size: cover; */
  background-repeat: no-repeat;
  position: relative;
}

.flexColum {
  height: 100%;
  margin-left: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  position: relative;
  z-index: 1;
  background-color: #222222;
  padding: 0 1em
}

.contenedor {
  max-width: 100%;
  overflow: hidden;
}

.containerFaltante {
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  right: 12%;
  top: 17%;
}

.pierBox {
  width: 75%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
}

.marca1 {
  height: 92px;
}

.marca2 {
  height: 50vh;
}

.dolchesterSection {
  padding: 5% 0;
  background-color: var(--gris-fondo);
}

.contenedorSecond {
  z-index: 1000;
  width: 100%;
  padding: 3rem 15rem;
}

.secondSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 5rem 0;
  gap: 5rem;
}

.grayDivider {
  overflow: hidden;
  height: 1vh;
}

.grayDivider img {
  margin-top: -10px;
  margin-bottom: -5px;
  height: 3vh;
}

.liverpoolBlue {
  background-image: url(../../../assets/Liverpool/Fondo2Liverpool.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: 60vh;
}

.liverBoxContainer {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin: auto;
}

.liverBlueText {
  position: relative;
  background-color: #222222;
  padding: 3em 6em 3em 2em;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid #45e7ec;
  box-shadow: 0 0 .2rem #326FAD,
    0 0 .5rem #326FAD,
    0 0 .5rem #326FAD,
    0 0 .5rem #326FAD,
    0 0 .5rem #326FAD,
    inset 0 0 .5rem #326FAD;
}

.liverBox {
  width: 45%;
}

.liverBlueText .title {
  color: #45e7ec;
  font-weight: 400;
}

.slogan {
  height: 100vh;
  padding-top: 85vh;
  padding-right: 20%;
}

.sloganContainer {
  display: flex;
  align-items: center;
  font-family: Helvetica, sans-serif;
}

.sloganText {
  font-family: "Eurostile LT W01 Bold", Arial, Helvetica, sans-serif;
  color: #fff;
  line-height: 1em;
  text-align: right;
}

.conteinerProductLeft {
  height: 200px;
  background-image: url(../../../assets/Liverpool/FondoGrisIzq.png);
  background-repeat: no-repeat;
  background-position: left;
  display: flex;
  gap: 100px;
  /* margin: 10px 8%; */
  justify-content: center;
  align-items: center;
}

.conteinerProductRight {
  height: 200px;
  background-image: url(../../../assets/Liverpool/FondoGrisDer.png);
  background-repeat: no-repeat;
  background-position: right;
  display: flex;
  gap: 100px;
  /* margin: 10px 8%; */
  justify-content: center;
  align-items: center;
}

.conteinerProductLeft .textProductBox {
  padding-left: 3em;
}

.conteinerProductRight .textProductBox {
  padding-right: 3em;
}

.legado {
  height: 50px;
}

.invertContainer {
  flex-direction: row-reverse;
  position: relative;
}

.pierBoxes {
  height: 350px;
  z-index: 1;
  transition: transform 1.3s;
}

.pierBoxes:hover {
  transform: scale(1.1);
}

.pierBoxesRed {
  height: 300px;
  z-index: 1;
  margin-left: 3rem;
}

.title {
  color: #b8860b;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 4px;
}

.subtitle {
  font-size: 18px;
  color: var(--negro);
  font-weight: 300;
}

.band {
  width: 4.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.band img {
  display: block;
  width: 2rem;
}

.thirdSection .subtitle {
  max-width: 100%;
}

.subtitlePop {
  font-size: var(--size-texto);
  line-height: 1.2em;
  color: #b1b1b1;
  font-weight: 400;
  width: 90%;
}

.bluePop {
  position: absolute;
  bottom: 4%;
  right: 3%;
  width: 12%;
}

.spaceSubtitle {
  padding-top: 15px;
}

.details {
  font-size: 16px;
  color: var(--gris-texto);
  padding-top: 15px;
}

.argentinianFlag {
  margin-top: 15px;
  height: 20px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  line-height: 20px;
}

.thirdSection {
  background-color: var(--gris-fondo);
  width: 100%;
  padding: 5rem 15rem;
}

/* .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    } */
.fondos {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.goldenSeparator {
  height: 10px;
  width: 100%;
  background-image: linear-gradient(0.25turn,
      var(--dorado-gradient),
      var(--blanco-gradient),
      var(--dorado-gradient));
}

.fifthSection {
  background-image: url(../../../assets/fondo-quinta-seccion.jpg);
  height: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.borde {
  top: 50;
  left: 50;
  height: 230px;
  z-index: 1;
}

.contenedorBorde {
  position: absolute;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 0;
}

.textContainerLeft {
  margin-left: 4rem;
}

.textContainerRight {
  margin-right: 4rem;
}

.conteinerProductFifthSection {
  display: flex;
  gap: 100px;
  margin: 20px 10%;
  justify-content: center;
  align-items: center;
  padding: 3%;
  border: 3px solid transparent;
  border-image-slice: 1;
  width: 80%;
}

.conteinerProductSixthSection {
  display: flex;
  gap: 100px;
  margin: 20px 10%;
  justify-content: center;
  align-items: center;
  padding: 3%;
  border: 3px solid transparent;
  border-image-slice: 1;
  width: 80%;
}

.titlefifthSection {
  color: var(--gris-titulo);
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 5px;
}

.subtitlefifthSection {
  color: #a5a4a4;
  font-size: 16px;
  font-weight: 300;
}

.sixthSection {
  background-color: var(--gris-fondo);
  padding: 3% 0 7%;
}

.sixthSection {
  background: url(../../../assets/background-black.webp);
  height: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: url(../../../assets/background-black.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

@media (max-width: 1100px) {
  .firstSection .containerFaltante {
    right: -10%;
    top: 15%;
  }

  .firstSection .pierBox {
    width: 70%
  }

  .liverpoolBlue {
    height: unset;
    padding: 2em 0;
    height: unset;
  }

  .liverBoxContainer {
    width: 90%;
  }

  .liverBlueText {
    flex-direction: column-reverse;
    padding: 2em;
  }

  .liverBox {
    width: 30%;
  }

  .conteinerProductLeft .textProductBox {
    padding-left: unset;
  }

  .conteinerProductRight .textProductBox {
    padding-right: unset;
  }
}

@media (max-width: 600px) {
  .firstSection .slogan {
    padding-right: 10%;
    padding-left: 0;
  }

  .firstSection .sloganContainer {
    justify-content: flex-end;
  }

  .firstSection .sloganContainer img {
    width: 60%;
    margin-top: 10px;
  }

  .liverpoolBlue {
    height: unset;
    padding: 20px;
  }

  .liverBoxContainer {
    width: 90%;
  }

  .liverBlueText {
    flex-direction: column-reverse;
    padding: 2em;
  }

  .liverBox {
    width: 90%;
  }

  .bluePop {
    width: 30%;
    right: 8%;
  }

  .conteinerProductLeft .textProductBox {
    padding-left: 3em !important;
  }

  .conteinerProductRight .textProductBox {
    padding-right: unset;
  }

  .contenedorSecond {
    z-index: 1000;
    width: 100%;
    padding: 0;
  }

  .section1 {
    padding: 0;
  }

  .legado {
    height: 35px;
  }

  .firstSection {
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start !important;
  }

  .faltante {
    display: none;
    left: 0;
  }

  .pierBox {
    width: 90% !important;
    margin: auto;
  }

  .flexColum {
    margin-left: 10%;
    display: none;
  }

  .marca {
    height: 150px;
  }

  .containerFaltante {
    max-width: 90%;
    overflow: hidden;
    position: absolute;
    left: 10%;
    top: 27%;
  }

  .textProductBox,
  .textContainerRight,
  .textContainerLeft {
    width: 100%;
  }

  .pierBoxes {
    object-fit: contain;
    width: 250px;
    height: 250px;
  }

  .pierBoxesRed {
    object-fit: contain;
    width: 250px;
    height: 250px;
  }

  .title {
    padding-bottom: 10px;
  }

  .dolchesterSection {
    width: 100%;
    padding: 5% 0;
  }

  .thirdSection {
    padding: 5% 0;
  }

  .secondSection {
    gap: 16rem !important;
  }

  .liverBlueText {
    /* padding-right: 2em; */
  }

  .liverBox {
    width: 85% !important;
    margin-bottom: 5%;
  }
}

@media (max-width: 920px) {
  .conteinerProductLeft .textProductBox {
    padding-left: 3em;
  }

  .conteinerProductRight .textProductBox {
    padding-right: unset;
  }

  .contenedorSecond {
    z-index: 1000;
    width: 100%;
    padding: 0;
  }
}

@media (max-width: 1200px) {
  .conteinerProductLeft .textProductBox {
    /* padding-left: unset; */
  }

  .conteinerProductRight .textProductBox {
    padding-right: unset;
  }

  .conteinerProductLeft,
  .conteinerProductRight,
  .conteinerProductFifthSection {
    flex-direction: column-reverse;
    justify-content: flex-end;
    background-image: none;
    gap: 20px;
  }

  .conteinerProductSixthSection {
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .textProductBox,
  .textContainerRight,
  .textContainerLeft {
    width: 70%;
  }

  .contenedorBorde {
    display: none;
  }

  .textContainerRight,
  .textContainerLeft {
    margin: 0;
  }

  .titlefifthSection {
    width: 100%;
  }

  .thirdSection {
    padding: 8% 15%;
  }

  .text {
    width: 80%;
    margin: 0 10%;
  }

  .dolchesterSection {
    padding: 5% 17%;
  }
}

@media (max-width: 1200px) {
  .firstSection {
    justify-content: center;
    background-position: 70% center;
    overflow-x: hidden;
  }

  .conteinerProductLeft .textProductBox {
    /* padding-left: unset; */
  }

  .conteinerProductRight .textProductBox {
    padding-right: unset;
  }

  .flexColum {
    margin-left: 10%;
  }

  .slogan {
    padding-top: 82vh;
    padding-left: 30%;
    padding-right: 10%;
  }

  .sloganContainer {
    flex-wrap: wrap;
  }

  .sloganText {
    font-size: 1.2em;
  }

  .secondSection {
    padding-bottom: 15rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 3rem 0;
    gap: 19em;
  }

  .textProductBox {
    padding: 2em;
    width: 100%;
  }

  .liverBlueText {
    padding-right: 2em;
  }

  .liverBox {
    width: 65%;
  }
}

@media screen and (min-width: 1600px) {

  /* Estilos para pantallas ultrawide */
  .pierBoxes {
    height: 355px;
    z-index: 1;
    margin-left: 1rem;
    margin-right: 5rem;
  }

  .textProductBox {
    width: 80%;
    /* padding: 0 5rem 0 0; */
  }

  .pierBoxesRed {
    height: 300px;
    z-index: 1;
    margin-right: 9rem;
  }

  .conteinerProductFifthSection {
    gap: 0;
  }

  .textContainerRight {
    margin-right: 16rem;
  }

  .borde {
    display: none;
  }

  .secondSection {
    padding-bottom: 0;
  }
}

.video {
  height: 100vh;
  width: 100%;
  z-index: 100;
  position: relative;
  margin-bottom: -4px;
  /* para sacar el borde blanco inferior */
}

@media screen and (max-width: 1330px) {
  .subtitle {
    max-width: 100%;
    /* font-size: 1em; */
  }
}

@media screen and (max-width: 1536px) {
  .containerFaltante {
    right: 0%;
    top: 13%;
  }

  .subtitle {
    max-width: 90%;
  }

  .thirdSection .subtitle {
    max-width: 100%;
  }

  .pierBox {
    width: 80%;
  }
}