.container {
  width: 100%;
  padding: 50px 0 0;
}

.primeraSeccion {
  height: 100vh;
  background-color: red;
  /* // TODO: falta la primera imagen del producto-1 aca*/
  /* background-image: url(../../../assets/fondo-izquierda-box.jpg) */
}

.firstSection {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(255, 81, 115);
  background: linear-gradient(152deg,
      rgba(255, 81, 115, 1) 0%,
      rgba(130, 18, 41, 1) 100%);
  position: relative;
}

.flexColum {
  height: 100%;
  margin-left: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 1rem;
  position: relative;
  z-index: 1;
}

.contenedor {
  max-width: 100%;
  overflow: hidden;
}

.containerFaltante {
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  left: 400px;
  top: 0;
  bottom: 0;
}

.pierBox {
  bottom: 0;
  z-index: 2;
  overflow: hidden;
  transform: rotate(-12deg);
  margin: auto;
  max-width: 100%;
}

.marca {
  height: 150px;
}

.dolchesterSection {
  background-color: var(--gris-fondo);
  width: 100%;
  padding: 5rem 15rem;
}

.contenedorSecond {
  z-index: 1000;
}

.backgroundContainer {
  max-height: 100vh;
}

.fourSection {
  position: relative;
  overflow-y: hidden;
}

.fondoContainer {

  background-image: url("../../../assets/HERO_DOLCH.png");
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

.boxContainer {
  width: 50%;
  margin: auto;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: baseline;
  height: 100vh;
}

.dolchesterHeader {
  /* height: 1625px; */
  position: relative;
  bottom: -30%;
  /* z-index: 2; */
  /* transform: rotate(22deg); */
  /* margin-left: 5rem; */
  /* max-width: 100%; */
  /* margin-top: 110px; */
  overflow: hidden;
  transition: transform 1.3s, scale 1.3s;
  /* Agregamos la transición de escala */
}

.dolchesterHeader:hover {
  transform: scale(1.1);
  /* Aplicamos la escala al hacer hover */
}

.subtitleHeader {
  position: absolute;
  top: 62%;
  left: 45%;
  font-size: 35px;
  width: 290px;
  text-transform: uppercase;
  line-height: 32px;
  color: #fff;
  font-family: "PPSANZ";
  font-weight: 200;
}

.dolchesterHeaderTexto {
  height: 50vh;
  position: absolute;
  top: 15%;
  right: 18%;
}

.dolchesterHeaderDiez {
  height: 15vh;
  position: absolute;
  top: 20%;
  left: 15%;
}

.niebla {
  background-image: url(../../../assets/fondo-niebla.jpg);
  background-position: center;
  background-size: cover;
  max-width: 100%;
  height: 100vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.legado {
  height: 50px;
}

.invertContainer {
  flex-direction: row-reverse;
  position: relative;
}

.pierBoxes {
  height: 475px;
  z-index: 1;
  transition: transform 1.3s;
}

.pierBoxesRed {
  height: 475px;
  z-index: 1;
  transition: transform 1.3s;
}

.pierBoxes:hover,
.pierBoxesRed:hover {
  transform: scale(1.1);
}

.title {
  color: #b8860b;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 4px;
}

.subtitle {
  font-size: 18px;
  color: var(--negro);
  font-weight: 300;
}

.band {
  width: 4.5rem;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.band img {
  display: block;
  width: 2rem;
}

.spaceSubtitle {
  padding-top: 15px;
}

.details {
  font-size: 16px;
  color: var(--gris-texto);
  padding-top: 15px;
}

.argentinianFlag {
  margin-top: 15px;
  height: 20px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
}

.thirdSection {
  background-color: var(--gris-fondo);
  padding: 3% 0;
}

.fondos {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  z-index: -2;
}

.goldenSeparator {
  position: relative;
  z-index: 5;
  height: 10px;
  width: 100%;
  background-image: linear-gradient(0.25turn,
      var(--dorado-gradient),
      var(--blanco-gradient),
      var(--dorado-gradient));
}

.fifthSection {
  background-image: url(../../../assets/fondo-quinta-seccion.jpg);
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 3rem 15rem;
  position: relative;
  z-index: 5;
}

.bordeDolche {
  top: 50;
  left: 50;
  height: 230px;
  z-index: 1;
}

.contenedorBorde {
  position: absolute;
  display: flex;
  align-content: center;
  align-items: center;
  z-index: 0;
}

.textContainerLeft {
  width: 50%;
}

.textContainerRight {
  width: 50%;
}

.conteinerProductFifthSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.conteinerProductSixthSection {
  display: flex;
  gap: 100px;
  margin: 20px 10%;
  justify-content: center;
  align-items: center;
  padding: 3%;
  border: 3px solid transparent;
  border-image-slice: 1;
  width: 80%;
}

.titlefifthSection {
  color: var(--gris-titulo);
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 5px;
}

.subtitlefifthSection {
  color: #a5a4a4;
  font-size: 16px;
  font-weight: 300;
}

.boxesP {
  color: #a5a4a4;
}

.sixthSection {
  background-color: var(--gris-fondo);
  padding: 3% 0 7%;
}

.sixthSection {
  background: url(../../../assets/background-black.webp);
  height: 100%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
  background: url(../../../assets/background-black.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

@media (max-width: 1200px) {

  .conteinerProductLeft,
  .conteinerProductRight,
  .conteinerProductFifthSection {
    flex-direction: column-reverse;
    justify-content: flex-end;
    background-image: none;
    gap: 20px;
  }

  .conteinerProductSixthSection {
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .textProductBox,
  .textContainerRight,
  .textContainerLeft {
    width: 70%;
  }

  .contenedorBorde {
    display: none;
  }

  .textContainerRight,
  .textContainerLeft {
    margin: 0;
  }

  .titlefifthSection {
    width: 100%;
  }

  .thirdSection {
    padding: 3% 15%;
  }

  .text {
    width: 80%;
    margin: 0 10%;
  }

  .dolchesterSection {
    padding: 5% 17%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .bordeDolche {
    padding: 6rem 0;
  }

  .lillos {
    height: 468px;
  }

  .contenedorTexto {
    width: 577px;
  }

  .section2 p {
    font-size: 12px;
  }

  .subtitleHeader {
    left: 50%;
    top: 60%;
  }

  .pierBoxes {
    height: 320px;
    z-index: 1;
    transition: transform 1.3s;
  }

  .pierBoxesRed {
    height: 320px;
    z-index: 1;
    transition: transform 1.3s;
  }
}

@media (max-width: 1200px) {
  .dolchesterHeader {
    top: 40%;
  }

  .dolchesterHeaderTexto {
    right: 12.5%;
    height: 40vh;
  }

  .dolchesterHeaderDiez {
    height: 10vh;
  }

  .subtitleHeader {
    bottom: 200px;
  }

  .firstSection {
    justify-content: center;
  }

  .flexColum {
    margin: 0;
  }

  .contenedor {
    display: none;
  }

  .secondSection {
    padding-bottom: 15rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0;
    gap: 18rem;
  }
}

@media screen and (min-width: 1600px) {
  .bordeDolche {
    top: 50;
    left: 50;
    height: 300px;
    z-index: 1;
  }

  .pierBoxes {
    height: 415px;
    z-index: 1;
    transition: transform 1.3s;
  }

  .pierBoxesRed {
    height: 415px;
    z-index: 1;
    transition: transform 1.3s;
  }

  .HeaderfifthSection {
    color: var(--gris-texto);
  }

  /* Estilos para pantallas ultrawide
  .pierBoxes {
    height: 350px;
    z-index: 1;
    margin-left: 1rem;
  }

  .textProductBox {
    padding: 0 5rem 0 0;
  }

  .pierBoxesRed {
    height: 300px;
    z-index: 1;
    margin-right: 9rem;
  }

  .conteinerProductFifthSection {
    gap: 0;
  }

  .textContainerRight {
    margin-right: 16rem;
  }

  .bordeDolche {
    display: none;
  }

  .secondSection {
    padding-bottom: 0;
  } */
}

@media (max-width: 600px) {

  /* .backgroundContainer {
    background-image: url(../../../assets/dolchester-mobile.png);
    background-position: center;
    background-size: cover;
    height: 100vh;
  } */
  .boxContainer {
    width: 110%;
  }

  .fondos {
    height: 50vh;
    width: 100%;
    display: none;
  }

  .dolchesterHeader {
    top: 50%;
    left: -10%
  }

  .dolchesterHeaderTexto {
    height: 35vh;
    top: 20%;
    right: 8%;
  }

  .dolchesterHeaderDiez {
    height: 10vh;
    left: 8%;
  }

  .subtitleHeader {
    display: none;
  }

  .fifthSection {
    padding: 1rem;
  }

  .legado {
    height: 35px;
  }

  .niebla {
    flex-direction: column;
  }

  .firstSection {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .faltante {
    display: none;
  }

  .textProductBox,
  .textContainerRight,
  .textContainerLeft {
    width: 100%;
  }

  .pierBoxes {
    object-fit: contain;
    width: 250px;
    height: 250px;
  }

  .pierBoxesRed {
    object-fit: contain;
    width: 250px;
    height: 250px;
  }

  .title {
    padding-bottom: 10px;
  }

  .dolchesterSection {
    width: 100%;
    padding: 11% 0;
  }

  .thirdSection {
    padding: 5% 0;
  }
}

.video {
  height: 100vh;
  width: 100%;
  z-index: 100;
  position: relative;
  margin-bottom: -4px;
  /* para sacar el borde blanco inferior */
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .bordeDolche {
    height: 410px;
  }

  .dolchesterHeaderTexto {
    right: 15.5%;
  }
}