.modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: white;
    width: 100wh;
    height: 90px;
    padding: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 1rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    font-family: "DIN2014", sans-serif;
    padding: 0 15rem;

}

.modal h4 {
    font-size: 14px;
}

.modal p {
    padding: 0;
    font-size: 12px;
    color: #868686;
    width: 63%;
    max-height: 100%;
    overflow: auto;
}

.modal button  {
    font-family: "DIN2014", sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    background: transparent;
    color: #b8860b;
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;
    border-color:  #b8860b;
    padding: 6px 8px;
    margin: 1rem;
    cursor: pointer;
}

.modalContent {
    display: flex;
    margin: auto;
    text-align: center;
    justify-content: space-between;
}

.footer {

    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 9rem;
}

.footer p {
    font-size: 10px;
    color: #868686;
    width: 50%;
}

.footer a {
    text-decoration: none;
}

.footer h4 {
    font-size: 10px;
    text-transform: uppercase;
    color:  #b8860b;
}

.footerButton  {
    width: 250px;
    font-size: 8px;
    text-transform: uppercase;
    background: transparent;
    color:  #b8860b;
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;
    border-color: solid 1 px  #b8860b;
    padding: 8px;
}

.footerButton:hover {

    background-color:  #b8860b;
    /* Green */
    color: white;
    cursor: pointer;
}

.politicas {
    color: blue;
}


.configCookies {
    width: 200px;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    color:  #b8860b;
    cursor: pointer;
    margin: 0;
    padding: 0 0 0 0;
}

@media (max-width: 480px) {
    .modal {
        padding: 0;
        height: 170px;
        flex-direction: column;
    }

    .modal p {
        margin: 1rem;
   
    }

}