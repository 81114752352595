.container {
  width: 100%;
  padding: 50px 0 0;
}

.primeraSeccion {
  height: 100vh;
  background-color: red;
  /* // TODO: falta la primera imagen del producto-1 aca*/
  /* background-image: url(../../../assets/fondo-izquierda-box.jpg) */
}

.firstSection {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top 10%;
  position: relative;
}
.carouselImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-top: 140px;
  margin-bottom: -10px;
}
.flexColum {
  height: 100%;
  display: flex;
  margin: 15em auto 0 41%;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  position: relative;
  z-index: 1;
  padding-top: 40px;
}

.contenedor {
  max-width: 100%;
  overflow: hidden;
}

.containerFaltante {
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  left: 400px;
  top: 0;
  bottom: 0;
}

.specialBox {
  bottom: 0;
  height: 80%;
  margin: 8em auto auto;
  max-width: 100%;
  overflow: hidden;
  scale: 1.2;
  transform: rotate(-13deg);
  z-index: 10;
  margin-left: 40rem;
  padding-right: 2rem;
  transition: transform 1.3s;
}

.specialBox:hover {
  transform: scale(1.1);
}

.redFlag {
  top: 0;
  right: 0;
  padding-right: 16rem;
  height: 99%;
  margin: 0 auto auto;
  scale: 1;
  overflow: hidden;
  z-index: 0;
  position: absolute;
}

.marca1 {
  height: 92px;
}

.dejateEncandilar {
  height: 176px;
}

.video {
  height: 100vh;
  width: 100%;
  z-index: 100;
  position: relative;
  margin-bottom: -4px;
  /* para sacar el borde blanco inferior */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.imgPier1:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

/* .imgPier2:hover {
    transform: scale(1.1);
    (150% zoom - Note: if the zoom is too large, it will go outside of the viewport)
} */
.contenedorBorde {
  display: flex;
  justify-content: center;
}

.borde {
  max-height: 75vh;
  width: 1519px;
  padding: 3rem;
}

.contenedorInterno {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.izquierda {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  height: 50%;
  line-height: 18px;
}

.contenedorTexto {
  height: 350px;
  width: 718px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5rem;
}

.contenedorTexto h2 {
  font-size: 26px;
  font-family: "Apparel";
  font-weight: 800;
}

.lillos {
  height: 655px;
  margin: 0;
  padding: 0;
  transform: rotate(-10deg);
  transition: transform 1.3s;
}

.lillos:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.contenedorSeparador {
  width: 50%;
}

.separador {
  width: 100%;
  position: absolute;
  bottom: 36%;
}

.derecha {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  height: 50%;
  width: 60%;
  line-height: 18px;
  padding-bottom: 1rem;
}

.contenedorTabacos {
  display: flex;
  margin-top: 3rem;
}

.derecha p {
  margin-bottom: 0;
}

.section2 h2 {
  padding: 0;
  color: #fff;
  font-size: 32px;
  font-family: "Apparel";
  font-weight: 400;
  margin: 0 0 2rem 2rem;
}

.section2 p {
  color: #fff;
  font-size: 21px;
  font-family: 'DIN2014';
  font-weight: 400;
  margin: 0;
  margin-left: 2rem;
  margin-bottom: 2rem;
  line-height: 24px;
}

.separador {
  width: 75px;
  height: 1px;
  background-color: #fff;
  margin: 0 2rem 2rem 2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.imgPier1:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

@media (max-width: 480px) {

  /* CSS styles for small devices */
  .rightSide {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section1 {
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
  }

  .imgSub {
    color: #fff;
    font-size: 12px;
    font-family: "Apparel";
    font-weight: lighter;
    margin-right: 0;
    position: absolute;
    bottom: 0;
    margin-bottom: 1rem;
  }

  .imgPier1 {
    height: 360px;
    margin: 0;
  }

  .section2 {
    height: 121vh;
    padding: 2rem;
  }

  .section2 h2 {
    font-size: 21px;
  }

  .section2 p {
    margin: 1rem;
  }

  .separador {
    display: none;
  }

  .izquierda {
    flex-direction: column;
    margin: 2rem 0;
  }

  .contenedorBorde {
    display: none;
  }

  .contenedorInterno {
    top: 240px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .lillos {
    height: 290px;
  }

  .contenedorTexto {
    height: auto;
    width: auto;
    margin-right: 0;
    text-align: center;
  }

  .containerFaltante {
    right: 0%;
    padding-left: 0%;
    left: 0%;
    max-width: 90vw;
    top: 12%
  }

  .specialBox {
    left: -2rem;
    width: 130vw;
    top: 10%;
    height: auto;
    position: absolute;
  }

  .redFlag {
    display: none;
  }
  .carouselImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 200px;
    margin-bottom: -10px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {

  /* CSS styles for medium devices */
  .rightSide {
    height: 100vh;
    display: flex;
    justify-content: space-around;
    padding-top: 2rem;
  }

  .section1 {
    justify-content: center;
    align-items: center;
    height: 100%;
    width: auto;
  }

  .imgSub {
    color: #fff;
    font-size: 12px;
    font-family: "Apparel";
    font-weight: lighter;
    margin-top: 3rem 0 0 0;
    margin-right: 0;
    width: 100%;
    text-align: center;
  }

  .imgPier1 {
    height: 325px;
    /* margin-bottom: 4rem; */
    margin-top: 110px;
  }

  .containerFaltante {
    right: 10%;
    /* padding-right: %; */
    left: unset;
  }

  .specialBox {
    left: -2rem;
    width: 130vw;
    top: 10%;
    height: auto;
    position: absolute;
  }

  .redFlag {
    display: none;
  }
  .carouselImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 200px;
    margin-bottom: -10px;
  }
}

@media (min-width: 1600px) {
  .imgPier1 {
    height: 1020px;
  }

  .imgSub {
    bottom: 115px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
  .imgSub {
    margin-right: 14.5rem;
  }

  .section2 {
    height: 79vh;
  }

  .borde {
    padding: 6.5rem 0;
  }

  .lillos {
    height: 468px;
  }

  .contenedorTexto {
    width: 577px;
  }

  .section2 p {
    font-size: 14px;
  }

  .subtitleHeader {
    left: 50%;
    top: 60%;
  }

  .pierBoxes {
    height: 320px;
    z-index: 1;
    transition: transform 1.3s;
  }

  .pierBoxesRed {
    height: 320px;
    z-index: 1;
    transition: transform 1.3s;
  }

  .firstSection {
    background-position: center top;
  }

  .flexColum {
    margin: 20em auto 0 39%;
  }

  .specialBox {
    margin-left: 35rem;
  }
}

@media (max-width: 1200px) {

  .conteinerProductLeft,
  .conteinerProductRight,
  .conteinerProductFifthSection {
    flex-direction: column-reverse;
    justify-content: flex-end;
    background-image: none;
    gap: 20px;
  }

  .conteinerProductSixthSection {
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .textProductBox,
  .textContainerRight,
  .textContainerLeft {
    width: 70%;
  }

  .contenedorBorde {
    display: none;
  }

  .textContainerRight,
  .textContainerLeft {
    margin: 0;
  }

  .titlefifthSection {
    width: 100%;
  }

  .thirdSection {
    padding: 8% 15%;
  }

  .text {
    width: 80%;
    margin: 0 10%;
  }

  .dolchesterSection {
    padding: 5% 17%;
  }

  .specialBox {
    max-width: unset;
    margin: 8em auto !important;
  }
}

@media (max-width: 1200px) {
  .firstSection {
    justify-content: center;
  }

  .secondSection {
    padding-bottom: 15rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0;
    gap: 18rem;
  }

  .dejateEncandilar {
    display: none;
  }
}

@media screen and (min-width: 1600px) {

  /* Estilos para pantallas ultrawide */
  .pierBoxes {
    height: 325px;
    z-index: 1;
    margin-left: 1rem;
  }

  .textProductBox {
    width: 80%;
    /* padding: 0 5rem 0 0; */
  }

  .pierBoxesRed {
    height: 300px;
    z-index: 1;
    margin-right: 9rem;
  }

  .conteinerProductFifthSection {
    gap: 0;
  }

  .textContainerRight {
    margin-right: 16rem;
  }

  .borde {
    display: none;
  }

  .secondSection {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .specialBox {
    margin-left: 20rem !important;
  }
}

@media screen and (max-width: 1536px) {
  .specialBox {
    margin-left: 25rem;
    max-height: 80%;
  }
}