.container {
  width: 100%;
  background-color: var(--gris-fondo);
}

.firstSection {
  height: 60vh;
  background-image: url(../../assets/background-inicio.jpeg);
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.secondSection {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: auto;
  text-align: justify;
  padding: 0rem 15rem 5rem 15rem;
  gap: 35px;
}

.subtitlesContainer {
  text-align: justify;
  padding: 5rem 15rem 1rem 15rem;
}

.subtitlesContainer h2, .subtitlesContainer h3 {
  margin: 0;
  font-size: 18px;
}

.title {
  color: #b8860b;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: var(17px);
  color: var(--negro-texto);
  font-weight: 500;
  margin-bottom: 10px;
}

.video {
  margin-top: 2rem;
  width: 630px;
  height: 353px;
}

.spaceSubtitle {
  padding-top: 15px;
}

.secondBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.brandsBox {
  display: flex;
  justify-content: space-between;
}

.thirdSection {
  height: 40vh;
}

.fourthSection {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: auto;
  margin: 0 10%;
  padding: 280px 0 0 0;
}

.firstBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
}

.secondBoxForms {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.firstPartForm {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 7%;
}

.boxImputs {
  width: 50%;
}

.lastPartForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.messageImput {
  width: 100%;
  color: #c70017;
  border: transparent;
  border-bottom: 1px solid black;
  outline: none;
  font-size: 14px;
  background-color: transparent;
  height: 70px;
}

.sendForm {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 3%;
}

.inputs {
  color: #b8860b;
  border: transparent;
  border-bottom: 1px solid black;
  outline: none;
  width: 100%;
  font-size: 14px;
  background-color: transparent;
  padding: 6px 0;
  margin: 5% 0;
  font-weight: 500;
}

.inputs::placeholder,
.messageImput::placeholder {
  color: #b8860b;
  padding-bottom: 10px;
}

.textForm {
  color: var(--gris-texto);
}

.buttonForm {
  font-size: 10px;
  width: 15%;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  color: #c70017;
  border-style: solid;
  border-width: 1px;
  border-radius: 16px;
  border-color: #c70017;
  padding: 8px;
  margin-bottom: 1rem;
  transition-duration: 0.4s;
}

.buttonForm:hover {
  background-color: #c70017;
  color: white;
  cursor: pointer;
}

.titleForm {
  color: #b8860b;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 10px;
}

.error {
  display: block;
  color: red;
  height: 25px;
  margin-bottom: 8px;
  font-size: 12px;
}

.containerInput {
  height: 60px;
}

/* estilos para los 4 logos */
.section3 {
  background-color: rgb(224, 224, 224);
  display: flex;
  align-content: center;
  flex-direction: column;
}

.section3 h3 {
  margin: 0 0 2rem 0;
  padding-top: 2rem;
  text-transform: uppercase;
  color: #c70017;
  font-weight: 400;
  font-size: 16px;
}

.flex {
  display: flex;
  justify-content: space-around;
}

.tresLogos1, .tresLogos2, .tresLogos3, .tresLogos4, .tresLogos5 {
  height: 50px;
}

.tresLogos3 {
  margin: 0 -35px;
  padding-bottom: 5px;
}

.tresLogos5 {
  mix-blend-mode: multiply;
}

.section3 {
  width: 100%;
}

.mainLogo {
  display: block;
  margin: 0 auto;
  max-width: 30%;
  height: auto;
  margin-top: 30px;
}

.band {
  max-height: 5%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

@media (max-width: 1200px) {
  .secondSection {
    flex-direction: column;
  }

  .title,
  .titleForm {
    text-align: center;
    width: 100%;
  }

  .firstBox {
    width: 100%;
  }

  .secondBox {
    align-items: center;
    margin: 0 auto;
  }

  .fourthSection {
    flex-direction: column;
    background-color: yellow;
  }

  .secondBoxForms {
    width: 100%;
  }

  .video {
    width: 600px;
    height: 380.5px;
  }

  .section3 {
    height: auto;
    padding: 1rem 0;
  }

  .section3 h3 {
    margin: 0;
    text-align: center;
  }

  .flex {
    display: flex;
    justify-content: space-between;
  }

  .tresLogos1 {
    height: 80px;
  }

  .tresLogos2 {
    height: 80px;
  }

  .tresLogos3 {
    height: 80px;
  }

  .tresLogos4 {
    height: 80px;
  }

  .tresLogos5 {
    height: 80px;
  }

  .title {
    margin: 0 0 2rem 0;
  }

  .secondBox {
    width: 100%;
  }

  .video {
    width: 600px;
    height: 380.5px;
  }

  .secondSection {
    padding: 20px 10% 10px;
  }

  .subtitlesContainer {
    padding: 2rem 2rem 0 2rem;
  }

  .mainLogo {
    max-width: 50%;
    margin-top: 20%;
  }
}

@media (max-width: 600px) {
  .firstSection {
    height: 35vh;
    background-size: initial;
    background-size: cover;
    background-position: center;
  }

  .title {
    margin: 0 0 2rem 0;
  }

  .secondSection {
    margin: 0 2rem;
    padding: 1rem;
    gap: 1rem;
  }

  .video {
    width: 100%;
    height: 56, 25%;
  }

  .firstPartForm {
    flex-direction: column;
    justify-content: flex-start;
  }

  .boxImputs {
    width: 100%;
  }

  .sendForm {
    flex-direction: column-reverse;
    align-items: center;
  }

  .textForm {
    text-align: left;
  }

  .buttonForm {
    width: 100%;
  }

  .section3 {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section3 h3 {
    margin: 0 0 2rem 0;
    text-align: center;
  }

  .tresLogos {
    height: 50px;
  }

  .flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .subtitlesContainer {
    padding: 2rem 2rem 0 2rem;
  }

  .mainLogo {
    max-width: 50%;
    margin-top: 35%;
  }
}